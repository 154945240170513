import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * List approval groups
 * @returns object
 */
export const listApprovalGroups = async (client_id = null) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Set filter by reseller_id
  let where = `where: { client: { reseller: ${reseller_id} } }`;
  if (client_id) {
    where = `where: { client: ${client_id} }`;
  }
  axiosOptions.data = {
    query: `
        query {
          approvalGroups(${where}) {
            id
            Name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Puporse: Get approval group by user and client id
 * @param {*} userId User id
 * @param {*} clientId Client id
 * @returns object Success or error - API response
 */
export const getUserApprovalGroups = async (userId = null, clientId) => {
  // Validate required fields
  userId = userId || localStorage.getItem("user");
  clientId = clientId || localStorage.getItem("client_id");
  // Set API headers and data
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Set API data
  axiosOptions.data = {
    query: `
        query {
          approvalGroups(where: { client: ${clientId}, users:[${userId}] }) {
            id
            Name
            users (where:{role_in: [3, 4, 5, 9]}){
              id
            }
          }
          client(id: ${clientId}) {
            id
            mode
          }
        }`,
  };
  // Call API
  try {
    // Get API response
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    // Return error
    return error;
  }
};
