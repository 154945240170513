import React, { useState } from "react";
import {
  CssBaseline,
  Link,
  Box,
  Typography,
  Container,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Custom components
import { Context } from "../context/UserState";
import SnackbarMessage from "../components/SnackbarMessage";
import LoginForm from "../components/login/LoginForm";
import ResetPassword from "../components/login/ResetPassword";

// API
import { login, checkTestingUser, IsTestUser } from "../api/Users";
import { listResellersFromUser, listResellers } from "../api/Resellers";
import {
  getCllientbySubdomain,
  listClientsFromReseller,
  listClientsFromUser,
} from "../api/Clients";

/**
 * Copyright section
 */
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">Avid.io</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  usePass: {
    marginTop: "25px",
  },
  buttonProgress: {
    [theme.breakpoints.down("lg")]: {
      position: "absolute",
      marginTop: -45,
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      marginTop: -45,
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      marginTop: -45,
    },
    [theme.breakpoints.down(400)]: {
      position: "absolute",
      marginTop: -122,
    },
    [theme.breakpoints.down(416)]: {
      position: "absolute",
      marginTop: -46,
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });
  const [usePass, setusePass] = useState(false);
  const [loading, setloading] = useState(false);
  const [view, setView] = useState("login");

  // Execute this on every render of this component
  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (token) {
      //if user exists continued
      if (user) {
        // If you are logged in, redirect to last visited path
        let pathname = localStorage.getItem("pathname");
        if (pathname) {
          //if token exists pass it to context and redirect
          dispatch({
            type: "SET_TOKEN",
            payload: token,
          });
          // The same with user_id
          dispatch({
            type: "SET_USER",
            payload: user,
          });
          history.push(pathname);
        } else {
          // if pathname no exists logout
          // Remove token from locastorage and context
          localStorage.clear();
          dispatch({
            type: "SET_TOKEN",
            payload: null,
          });
          // The same with user_id
          dispatch({
            type: "SET_USER",
            payload: null,
          });
        }
      } else {
        // Remove token from localstorage and context
        localStorage.clear();
        dispatch({
          type: "SET_TOKEN",
          payload: null,
        });
        return null;
      }
    } else if (state.token) {
      // if token is recently generated...
      // Set token to localStorage
      localStorage.setItem("token", state.token);
      // Set user to localStorage
      localStorage.setItem("user", state.user);
      // Check user role
      const role = localStorage.getItem("role");
      // Get all user resellers
      (role === "superadmin"
        ? listResellers()
        : listResellersFromUser(state.user)
      )
        .then((response) => {
          let reseller_id =
            response.data.resellers && response.data.resellers.length > 0
              ? response.data.resellers[0].id
              : 0;
          // Set first user reseller to localStorage
          localStorage.setItem("reseller_id", reseller_id);
          // Redirect
          if (role) {
            if (role == "customer") {
              listClientsFromUser({ user_id: state.user }).then((result) => {
                if (result && result.data) {
                  const clients = result.data.clients || [];
                  let client_id =
                    clients.length > 0 && clients[0].id ? clients[0].id : 0;
                  // set to localstorage
                  localStorage.setItem("client_id", client_id);
                }
              });
              history.push("/store/products");
            } else if (role.startsWith("client")) {
              checkTestingUser(state.user).then((isTestUser) => {
                (isTestUser
                  ? listClientsFromReseller()
                  : listClientsFromUser({ user_id: state.user })
                ).then((result) => {
                  if (result && result.data) {
                    let client_id =
                      result.data.clients.length > 0 &&
                      result.data.clients[0].id
                        ? result.data.clients[0].id
                        : 0;
                    //set to localstorage
                    localStorage.setItem("client_id", client_id);
                    let routes = {
                      clientadmin: `/app/client/${client_id}`,
                      clientproductmanager: `/app/client/${client_id}/products`,
                      clientusermanager: `/app/client/${client_id}/users`,
                      clientorderapprover: `/app/client/${client_id}/approval`,
                    };
                    history.push(routes[role]);
                  }
                });
              });
            } else {
              history.push("/app/products");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setError({
            open: true,
            message: `Error listing resellers`,
            severity: "error",
          });
        });
    }
  });

  //Valiade client_code
  const validate_clienUser = async (values) => {
    if (!loading) {
      setloading(true);
    }
    let client_code = values.split("-");
    if (client_code.length <= 2) {
      let client_id = null;
      if (client_code.length == 2) {
        await getCllientbySubdomain({
          subdomain: client_code[0],
          username: values,
        }).then((xhr) => {
          if (xhr && xhr.data.clients.length >= 1) {
            let client = xhr.data.clients[0];
            if (client && client.users.length >= 1) {
              client_id = client.id;
              //Set client_id to localStorage
              localStorage.setItem("client_id", client_id);
            } else {
              setError({
                open: true,
                message:
                  "Error: You don't belong to the client you are trying to access",
                severity: "error",
              });
              setloading(false);
              return null;
            }
          } else {
            setError({
              open: true,
              message:
                "Error: The client you are trying to access doesn't exist",
              severity: "error",
            });
            setloading(false);
            return null;
          }
        });
      } else {
        const username = client_code[0];
        const emailRegex =
          /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,63}$/i;
        if (emailRegex.test(username)) {
          client_id = 0;
          setError({
            open: true,
            message: "Error: To enter with email, password is required",
            severity: "error",
          });
          return null;
        } else {
          await IsTestUser(null, username).then((isTest) => {
            if (isTest == true) {
              client_id = 0;
              //Set client_id to localStorage
              localStorage.setItem("client_id", client_id);
            } else if (isTest == false) {
              setloading(false);
              setError({
                open: true,
                message: "Error: Sorry, this user is not a test user",
                severity: "error",
              });
              return null;
            } else {
              setloading(false);
              setError({
                open: true,
                message: "Error: Sorry, this user doesn't exist",
                severity: "error",
              });
              return null;
            }
          });
        }
      }
      //wait a second, if there is still no answer
      while (client_id === null) {
        await PleaseWaitMoment(1000);
      }
    } else {
      setError({
        open: true,
        message: "Error: Client code no found, example ClientCode-UserName",
        severity: "error",
      });
      setloading(false);
    }
  };

  /**
   * Formik validation schema
   */
  const validationSchema = yup.object({
    username: !usePass
      ? yup.string("Enter your username").required("Username is required")
      : yup
          .string("Enter your Email or username")
          .required("Email or username is required"),
    password: usePass
      ? yup
          .string("Enter your password")
          .min(8, "Password should be of minimum 8 characters length")
          .required("Password is required")
      : "",
  });

  /**
   * Create a delay
   * @param {int} ms
   * @returns
   */
  const PleaseWaitMoment = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  /**
   * Formik hook
   */
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const password = "uHFLoLmlcqg1&y!KhbnQW9LIoI*a9w3O";
      if (!usePass) {
        values.secret_password = password;
        await validate_clienUser(values.username);
      } else if (usePass) {
        values.user_name = values.username;
        setloading(true);
      }

      if (!usePass) {
        let client_id = localStorage.getItem("client_id");
        if (!client_id) {
          setloading(false);
          return null;
        } else {
          const code_client = values.username.includes("-");
          if (code_client) {
            values.user_name = values.username;
          } else {
            client_id == 0 ? (values.password = "") : "";
            values.user_name = values.username;
          }
        }
      }

      login(values).then((response) => {
        //remove client_id
        localStorage.removeItem("client_id");
        if (response.data.errors) {
          let error_message = usePass
            ? response.data.errors[0].extensions.exception.data
              ? response.data.errors[0].extensions.exception.data.message[0]
                  .messages[0].message
              : response.data.errors[0].message
            : "Account requires password";
          setloading(false);
          setError({
            open: true,
            message: `Error: ${error_message}`,
            severity: "error",
          });
        } else if (response.data.data.login) {
          setloading(false);
          // Set role to localStoage
          localStorage.setItem("role", response.data.data.login.user.role.type);
          // On success login, set user to the context (this will be rendered again)
          dispatch({
            type: "SET_USER",
            payload: response.data.data.login.user.id,
          });
          // Set token to the context
          dispatch({
            type: "SET_TOKEN",
            payload: response.data.data.login.jwt,
          });
        }
      });
    },
  });

  /**
   * Open/close SnackbarMessage
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "error",
    });
  };

  // Render view
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Avid.io
        </Typography>
        {view == "login" ? (
          <LoginForm
            classes={classes}
            formik={formik}
            loading={loading}
            setView={setView}
            usePass={usePass}
            setusePass={setusePass}
          />
        ) : (
          <ResetPassword
            classes={classes}
            setView={setView}
            setError={setError}
          />
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
}
