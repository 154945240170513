import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { searchClientApprovalGroupUsers } from "../../api/Users";
import { useParams } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import { createApprovalGroup, updateApprovalGroup } from "../../api/Clients";
import SearchIcon from "@material-ui/icons/Search";

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup
    .string("Approval Group Name")
    .required("Approval group name is required")
    .test(
      "not empty",
      "Approval Group must contain characters",
      function (value) {
        return /\S/.test(value);
      }
    ),
  Approvers: yup
    .array(
      yup.object({
        id: yup.number().required("This field is required"),
      })
    )
    .min(1, "At least one approval is required")
    .required("This field is required"),
});

function ApprovalGroupModal(props) {
  const [initial_users, setInitialUsers] = useState(null);
  const [initialApprovals, setInitialApprovals] = useState(null);
  const [users, setUsers] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [initialValues, setInitialValues] = useState({
    Name: "",
    Approvers: [],
  });
  const [saving, setSaving] = useState(false);
  const role = localStorage.getItem("role");
  let { client_id, client } = useParams();
  const [searchTerms, setSearchTerms] = React.useState("");
  const [searchTermsApprovals, setSearchTermsApprovals] = React.useState("");
  const [searching, setSearching] = useState(false);
  const [searchingApprovals, setSearchingApprovals] = useState(false);

  // Formik hook
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSaving(true);
      let user_ids = [];
      selectedUsers.forEach((user) => {
        user_ids.push(user.id);
      });
      // For approvals
      selectedApprovals.forEach((user) => {
        user_ids.push(user.id);
      });
      const clientId = client_id || client;
      if (props.selectedApprovalGroup.id) {
        updateApprovalGroup(
          props.selectedApprovalGroup.id,
          clientId,
          values.Name,
          user_ids
        ).then((res) => {
          if (res) {
            setSelectedUsers([]);
            setSelectedApprovals([]);
            setInitialValues({ Name: "", Approvers: [] });
            props.reloadClient();
            props.handleClose();
            setSaving(false);
          }
        });
      } else {
        createApprovalGroup(clientId, values.Name, user_ids).then((res) => {
          if (res) {
            setSelectedUsers([]);
            setSelectedApprovals([]);
            setInitialValues({ Name: "", Approvers: [] });
            props.reloadClient();
            props.handleClose();
            setSaving(false);
          }
        });
      }
    },
  });

  // Use Effect to trigger after the search input changes
  React.useEffect(() => {
    setSearching(true);
    let timer1 = setTimeout(() => {
      // Get client id
      const clientId = client_id || client;
      // Get users list
      searchClientApprovalGroupUsers(clientId, searchTerms, [7])
        .then((result) => {
          if (result && result.data && result.data.users) {
            let users = result.data.users;
            setUsers(users);
            if (initial_users === null) {
              setInitialUsers(users);
            }
            setSearching(false);
          }
        })
        .catch((error) => {
          console.log("searchClientApprovalGroupUsers -----> ", error);
        });
    }, 500);
    // Cleanup function
    return () => {
      clearTimeout(timer1);
    };
  }, [searchTerms]);

  // Use Effect to trigger after the search input changes for approvals
  React.useEffect(() => {
    setSearchingApprovals(true);
    let timer1 = setTimeout(() => {
      // Get client id
      const clientId = client_id || client;
      // Get users list
      searchClientApprovalGroupUsers(
        clientId,
        searchTermsApprovals,
        [3, 4, 5, 9]
      )
        .then((result) => {
          if (result && result.data && result.data.users) {
            let users = result.data.users;
            setApprovals(users);
            if (initialApprovals === null) {
              setInitialApprovals(users);
            }
            setSearchingApprovals(false);
          }
        })
        .catch((error) => {
          console.log("searchClientApprovalGroupUsers -----> ", error);
        });
    }, 500);
    // Cleanup function
    return () => {
      clearTimeout(timer1);
    };
  }, [searchTermsApprovals]);

  useEffect(() => {
    formik.resetForm({ values: { Name: "" } });
    if (props.selectedApprovalGroup.id) {
      props.selectedApprovalGroup.users.forEach((user) => {
        user.name = user.Details
          ? user.Details.FirstName + " " + user.Details.LastName
          : user.username;
      });
      setSelectedUsers([...props.selectedApprovalGroup.users]);
      // For approvals
      props?.selectedApprovalGroup?.approvals.forEach((user) => {
        user.name = user.Details
          ? user.Details.FirstName + " " + user.Details.LastName
          : user.username;
      });
      setSelectedApprovals([...props.selectedApprovalGroup.approvals]);
      setInitialValues({
        Name: props.selectedApprovalGroup.Name,
        Approvers: props.selectedApprovalGroup.approvals,
      });
    } else {
      setInitialValues({ Name: "" });
      setSelectedUsers([]);
      setSelectedApprovals([]);
    }
  }, [props.open]);

  /**
   * Purpose: Unselect users
   * @param {*} user user object
   * @param {*} type users or approvals
   */
  const unselectUser = (user, type = "users") => {
    const users = type === "users" ? selectedUsers : selectedApprovals;
    for (let index = 0; index < users.length; index++) {
      if (users[index].id == user.id) {
        users.splice(index, 1);
      }
    }
    if (type === "users") {
      setSelectedUsers([...users]);
    } else {
      setSelectedApprovals([...users]);
      formik.setFieldValue("Approvers", [...users] || []);
    }
  };

  /**
   * Puporse: Select users
   * @param {*} user user object
   * @param {*} type users or approvals
   */
  const selectUsers = (user, type = "users") => {
    let alreadyInList = false;
    const users = type === "users" ? selectedUsers : selectedApprovals;
    users.forEach((selected) => {
      if (selected.id == user.id) alreadyInList = true;
    });
    if (!alreadyInList) {
      if (type === "users") {
        setSelectedUsers([...selectedUsers, user]);
      } else {
        setSelectedApprovals([...selectedApprovals, user]);
        formik.setFieldValue("Approvers", [...selectedApprovals, user] || []);
      }
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="alert-dialog-title">Approval Groups</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="Name"
              margin="dense"
              label="Name"
              variant="outlined"
              value={formik.values.Name}
              onChange={formik.handleChange}
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={formik.touched.Name && formik.errors.Name}
            />
          </Grid>
          {/**Approvals search */}
          <Grid item xs={12}>
            <Autocomplete
              autoComplete
              autoHighlight
              freeSolo
              fullWidth
              id="Approvers"
              clearOnBlur
              disableClearable
              options={searchingApprovals ? [] : approvals}
              getOptionDisabled={(option) =>
                selectedApprovals.map((user) => user.id).includes(option.id)
              }
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.search_label}
              loading={searchingApprovals}
              onChange={(event, value) => {
                if (event.key === "Enter" && !value.id) {
                  event.preventDefault();
                  return false;
                } else if (value) {
                  selectUsers(value, "approvals");
                }
              }}
              value={formik.values.Approvers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Approvals"
                  margin="none"
                  onChange={(e) => {
                    setSearchTermsApprovals(e.target.value);
                  }}
                  placeholder="Search approvals"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(formik.errors.Approvers)}
                  helperText={formik.errors.Approvers}
                />
              )}
            />
          </Grid>
          {/**Chips - Users selected */}
          <Grid item xs={12}>
            {selectedApprovals.map((user, index) => {
              return role !== "superadmin" &&
                role !== "reselleradmin" &&
                user.test == true ? (
                ""
              ) : (
                <Chip
                  style={{ margin: "2px", maxWidth: "100%" }}
                  key={index}
                  label={user.name}
                  onDelete={() => {
                    unselectUser(user, "approvals");
                  }}
                />
              );
            })}
          </Grid>
          {/** Users Search */}
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              style={{ marginLeft: "auto" }}
              id="search"
              disableClearable
              options={searching ? [] : users}
              getOptionDisabled={(option) =>
                selectedUsers.map((user) => user.id).includes(option.id)
              }
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.search_label}
              autoHighlight
              loading={searching}
              onChange={(event, value) => {
                if (event.key === "Enter" && !value.id) {
                  event.preventDefault();
                  return false;
                } else {
                  value ? selectUsers(value, "users") : null;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users"
                  margin="none"
                  onChange={(e) => {
                    setSearchTerms(e.target.value);
                  }}
                  placeholder="Search users"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedUsers.map((user, index) => {
              return role == "superadmin" && role == "reselleradmin" ? (
                <Chip
                  style={{ margin: "2px", maxWidth: "100%" }}
                  key={index}
                  label={user.name}
                  onDelete={() => unselectUser(user, "users")}
                />
              ) : role !== "superadmin" &&
                role !== "reselleradmin" &&
                user.test == true ? (
                ""
              ) : (
                <Chip
                  style={{ margin: "2px", maxWidth: "100%" }}
                  key={index}
                  label={user.name}
                  onDelete={() => unselectUser(user, "users")}
                />
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        <Button
          variant={"contained"}
          color={"default"}
          size={"small"}
          onClick={() => {
            props.handleClose();
            setUsers(initial_users);
            setApprovals(initialApprovals);
          }}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={formik.handleSubmit}
          disabled={saving}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApprovalGroupModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reloadClient: PropTypes.reloadClient,
  selectedApprovalGroup: PropTypes.object,
};

export default ApprovalGroupModal;
