import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import ApprovalGroupModal from "./ApprovalGroupModal";
import ConfirmationDialog from "../ConfirmationDialog";
import { deleteApprovalGroup } from "../../api/Clients";
import { Skeleton } from "@material-ui/lab";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  approvalContainer: {
    maxHeight: "230px",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "10px",
  },
}));

function ApprovalGroups(props) {
  const [openApprovalGroups, setOpenApprovalGroups] = useState(false);
  const [selectedApprovalGroup, setSelectedApprovalGroup] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const classes = useStyles();

  const closeApprovalGroups = () => {
    setSelectedApprovalGroup({});
    setOpenApprovalGroups(false);
  };

  const triggerApprovalGroupsModal = () => {
    setSelectedApprovalGroup({});
    setOpenApprovalGroups(true);
  };

  const triggerEditApprovalGroup = (group) => {
    setSelectedApprovalGroup(group);
    setOpenApprovalGroups(true);
  };

  //Open delete dialog and select the id of the user to be deleted
  const openDeleteApprovalGroup = (group) => {
    setSelectedApprovalGroup(group);
    setOpenConfirmation(true);
  };

  const confirmDeleteApprovalGroup = () => {
    deleteApprovalGroup(selectedApprovalGroup.id).then((res) => {
      if (res.data.deleteApprovalGroup) {
        props.reloadClient();
      }
    });
  };
  return (
    <div>
      <Paper className={classes.paper}>
        {props.loading ? (
          // Skeleton
          <Grid container spacing={2}>
            <Grid item xs={8} md={6}>
              <Skeleton height={41.6} />
            </Grid>
            <Grid item xs={4} md={6}>
              <Skeleton height={36} width={64} style={{ float: "right" }} />
            </Grid>
            {[1, 2].map((item, index) => {
              return (
                <Grid item xs={12} md={6} key={index}>
                  <Skeleton height={32} width="50%" />
                  <Skeleton height={29.6} width="25%" />
                  <Skeleton
                    height={36}
                    width="25%"
                    style={{ float: "right" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={8} md={6}>
              <Typography variant="h4">Approval Groups</Typography>
            </Grid>
            <Grid item xs={4} md={6}>
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={triggerApprovalGroupsModal}
              >
                Add
              </Button>
            </Grid>
            <Grid container spacing={2} className={classes.approvalContainer}>
              {props.approvalGroups.length ? (
                //map
                props.approvalGroups.map((group, index) => {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <Typography
                        variant="h5"
                        component="h2"
                        noWrap={true}
                        style={{ cursor: "pointer" }}
                        onClick={() => triggerEditApprovalGroup(group)}
                      >
                        {group.Name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Users: {group.users.length}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Approvers: {group?.approvals?.length}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={() => openDeleteApprovalGroup(group)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5">No Approval Groups</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
      <ApprovalGroupModal
        open={openApprovalGroups}
        handleClose={closeApprovalGroups}
        reloadClient={props.reloadClient}
        selectedApprovalGroup={selectedApprovalGroup}
      />
      <ConfirmationDialog
        open={openConfirmation}
        dialogTitle="Delete Approval Group"
        dialogContentText="Are you sure you want to delete this approval group?"
        handleClose={() => {
          setOpenConfirmation(false);
        }}
        confirmButtonClick={confirmDeleteApprovalGroup}
      />
    </div>
  );
}

ApprovalGroups.propTypes = {
  approvalGroups: PropTypes.array,
  reloadClient: PropTypes.reloadClient,
  loading: PropTypes.bool,
};

export default ApprovalGroups;
