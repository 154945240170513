import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  TextField,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  CircularProgress,
  Hidden,
  Link,
  Breadcrumbs,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Skeleton from "@material-ui/lab/Skeleton";
//Containers
import UserContainer from "../components/User/UserContainer";
import UserPointsContainer from "../components/User/UserPointsContainer";
import UserAddresses from "../components/User/UserAddresses";
import AlertDialog from "../components/AlertDialog";
import SnackbarMessage from "../components/SnackbarMessage";
//Api
import {
  searchOrdersInvoices,
  getOrderInvoiceByUser,
} from "../api/OrderInvoices";
import { deleteUser, getUserById, updateUserById } from "../api/Users";

//Styles needed for resposives view
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  tableCell: {
    paddingLeft: "16px",
    paddingRight: "-8px",
  },
  tableCellName: {
    paddingLeft: "16px",
    paddingRight: "-8px",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));

/**
 * Main component
 * @param {object} props
 * @returns
 */
export default function UserProfile(props) {
  const classes = useStyles();
  const [ordersList, setOrders] = useState([]);
  const [loading_search, setLoadingSearch] = useState(false);
  const history = useHistory();
  const [OrderInvoices, setOrdersInvoices] = useState([]);
  const [loadingtable, setLoadingTable] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showTable, setShowTable] = useState(true);
  //check if url contains user id
  let { client, id } = useParams();
  let client_id_param = useParams().client_id;
  // Check URL
  let route_user = useRouteMatch("/app/client/:client/user/:id");
  const user_id = id ? id : localStorage.getItem("user");
  const [user, setUser] = useState();
  const role = localStorage.getItem("role");
  const client_id = client
    ? client
    : JSON.parse(localStorage.getItem("store_user"))
    ? JSON.parse(localStorage.getItem("store_user")).client_id
    : localStorage.getItem("client_id")
    ? localStorage.getItem("client_id")
    : "No client";
  const isAdmin =
    role == "superadmin" || role == "reselleradmin" || role == "clientadmin"
      ? true
      : false;
  const [disableLabes, setDisableLabels] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  let location = useLocation();

  //Message errors
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  //Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**Initial user data*/
  useEffect(() => {
    //set from_store
    localStorage.setItem("from_store", "UserProfile");

    //Get initial user ordes invoices
    getOrderInvoices();
    //Get user if id
    if (id) {
      getUser(id);
    }
    //Remove previous path item when the component is unmounted
    return function cleanup() {
      localStorage.removeItem("previousPath");
    };
  }, []);

  //Get User ordes invoices
  const getOrderInvoices = () => {
    if (user_id) {
      getOrderInvoiceByUser(user_id, true).then((xhr) => {
        if (xhr.data.orderInvoices && xhr.data.orderInvoices.length >= 1) {
          const orders = xhr.data.orderInvoices;
          for (const order of orders) {
            if (order.customer) {
              const customer = order.customer;
              order.address =
                customer.billing_address.Street +
                ", " +
                customer.billing_address.Street2 +
                " " +
                customer.billing_address.Zip +
                " " +
                customer.billing_address.State +
                " " +
                customer.billing_address.City;
            }
            //Validating if the order has products
            if (
              order &&
              order.line_items &&
              Array.isArray(order.line_items) &&
              order.line_items.length > 0
            ) {
              const orderProducts = order.line_items;
              let productName = "";
              for (const product of orderProducts) {
                const name = product.LineItemJson.ProductName;
                if (productName.length == 0) {
                  productName = name;
                } else {
                  productName = productName + ", " + name;
                }
              }
              order.ProductsName = productName;
            }
          }
          setOrdersInvoices(orders);
          setLoadingTable(false);
          setShowTable(true);
        } else {
          setShowTable(false);
        }
      });
    }
  };

  //Get user
  const getUser = (user_id) => {
    if (user_id) {
      getUserById(user_id).then((result) => {
        const user = result?.data?.user || null;
        if (user) {
          // User confirmed
          setConfirmed(user.confirmed);
          // Validate user details
          if (user?.Details) {
            // Get user full name if exist
            const FirstName = user?.Details?.FirstName || "";
            const LastName = user?.Details?.LastName || "";
            if (FirstName != "") {
              user.label_user = `${FirstName} ${LastName}`.trim();
            }
            // Get username if full name is not available
            else {
              user.label_user = user.username;
            }
            user.label_username = user.Details.FirstName
              ? user.Details.FirstName
              : user.username;
          }
          // Get username if user details are not available
          else {
            user.label_user = user.username;
            user.label_username = user.username;
          }
          // Filter user view and edit options
          if (user.role.type) {
            const user_role = user.role.type;
            switch (role) {
              case "clientproductmanager":
                if (user_role == "clientadmin") {
                  setDisableLabels(true);
                }
                break;
              case "clientusermanager":
                switch (user_role) {
                  case "clientadmin":
                    setDisableLabels(true);
                    break;
                  case "clientproductmanager":
                    setDisableLabels(true);
                    break;
                  case "clientusermanager":
                    setDisableLabels(false);
                    break;
                  case "clientorderapprover":
                    setDisableLabels(false);
                    break;
                  case "customer":
                    setDisableLabels(false);
                    break;
                  default:
                    setDisableLabels(false);
                    break;
                }
                break;
              case "clientorderapprover":
                if (user_role != "customer") {
                  setDisableLabels(true);
                }
                break;
              case "customer":
                setDisableLabels(false);
                break;
              default:
                setDisableLabels(false);
                break;
            }
          }
          setUser(user);
        }
      });
    }
  };

  /**
   * Puporse: Enable or disable user
   * @param {*} user User data
   */
  const handleModifyUser = (user) => {
    if (user.id) {
      // user data to update
      const user_data = {
        id: user.id,
        username: user.username.trim(),
        email: user.email,
        confirmed: confirmed ? false : true,
        first_name: user.Details.FirstName ? user.Details.FirstName.trim() : "",
        last_name: user.Details.LastName ? user.Details.LastName.trim() : "",
        billing_department: user.Details.BillingDepartment
          ? user.Details.BillingDepartment
          : "",
        site: user.Details.Site ? user.Details.Site : "",
        role: user.role.id,
        test: user.test,
        Employee_ID: user.Employee_ID,
      };

      // Activating or deactivating user
      updateUserById(user_data, true)
        .then((result) => {
          if (result.errors) {
            setError({
              open: true,
              message: `Error: ${result.error[0].message}`,
              severity: "error",
            });
          } else {
            // Show snackbar
            setError({
              open: true,
              message: `User ${confirmed ? "Disabled" : "Enable"} Successfully`,
              severity: "success",
            });
            // Set Confirmed value
            setConfirmed(result.data.updateUser.user.confirmed);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };

  /**
   * Delete single user
   */
  const removeUser = () => {
    // Make request
    deleteUser(user_id)
      .then((result) => {
        // If 200 OK, check correct response props
        if (
          result &&
          result.data &&
          result.data.deleteUser &&
          result.data.deleteUser.user
        ) {
          history.push(client ? `/app/client/${client}/users` : `/app/users`);
        } else {
          // Else show error snackbar
          setError({
            open: true,
            message: `Error deleting user`,
            severity: "error",
          });
        }
      })
      // Else show error snackbar
      .catch(() => {
        setError({
          open: true,
          message: `Error requesting for delete user`,
          severity: "error",
        });
      });
  };

  return (
    <Container
      style={{ marginTop: "85px" }}
      maxWidth="xl"
      className={role == "customer" ? "" : classes.mainContainer}
    >
      <Grid container spacing={3} style={{ padding: "20px" }} id="user_profile">
        <Grid item xs={12}>
          {user ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    color="textSecondary"
                    onClick={() =>
                      history.push(
                        location.pathname.includes("store")
                          ? `/app/client/${
                              client ? client : client_id_param
                            }/store/products`
                          : !client
                          ? `/app/users`
                          : route_user && route_user.isExact
                          ? `/app/client/${client}/users`
                          : ""
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {location.pathname.includes("store") ? `Store` : `Users`}
                  </Link>
                  <Typography color="textSecondary">
                    {`${user.label_user}'s profile`}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                justify="flex-end"
              >
                {confirmed ? null : (
                  <Grid item>
                    <AlertDialog
                      dialogTitle="Delete User"
                      dialogContentText="User will be removed from the list. Do you want to continue?"
                      openButtonText="Delete Permanently"
                      confirmButtonClick={() => removeUser()}
                    />
                  </Grid>
                )}
                {isAdmin || !disableLabes ? (
                  <Grid item>
                    <AlertDialog
                      openButtonFullWidth="fullWidth"
                      openButtonClassName={classes.Button}
                      dialogTitle={confirmed ? "Disable User" : "Enable User"}
                      dialogContentText={
                        confirmed
                          ? "The user will be disabled from the list. Do you want to continue?"
                          : "The user will be enabled in the list. Do you want to continue?"
                      }
                      openButtonText={
                        confirmed ? "DISABLE USER" : "ENABLE USER"
                      }
                      confirmButtonColor={confirmed ? "secondary" : "primary"}
                      openButtonColor={confirmed ? "secondary" : "primary"}
                      confirmButtonClick={() => handleModifyUser(user)}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              {/** Show success or error */}
              <SnackbarMessage
                open={error.open}
                severity={error.severity}
                message={error.message}
                handleClose={handleCloseSnackbar}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <UserContainer
              user_id={user_id}
              view={localStorage.getItem("role")}
              fromStore={id ? true : false}
              client_id={client_id}
              isAdmin={isAdmin}
              disableLabes={disableLabes}
              route_user={route_user}
              clients={props.clients}
            />
          </Grid>

          <Grid
            container
            spacing={3}
            style={{ paddingTop: "20px" }}
            id="user_profile"
          >
            <Grid item xs={12} md={12}>
              <UserAddresses
                user_id={user_id}
                isAdmin={isAdmin}
                disableLabes={disableLabes}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <UserPointsContainer
            user_id={user_id}
            client_id={client_id}
            isAdmin={isAdmin}
            clients={props.clients}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Order Invoice History
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} md={2} />
        </Hidden>
        <Grid item xs={12} sm={12} lg={4}>
          <Autocomplete
            autoComplete
            autoHighlight
            clearOnEscape
            freeSolo
            style={{ marginLeft: "auto" }}
            id="search"
            disableClearable
            clearOnBlur
            options={ordersList}
            getOptionLabel={(option) => option.search_label}
            onChange={(event, value) => {
              if (event.key === "Enter" && !value.id) {
                event.preventDefault();
                return false;
              } else {
                role == "customer"
                  ? history.push(`/store/orders/${value.id}`)
                  : history.push(`/app/orders/${value.id}`);
              }
            }}
            loading={loading_search}
            onInputChange={(event, value) => {
              if (value.length > 2) {
                setLoadingSearch(true);
                //search by search term
                searchOrdersInvoices({
                  search_term: value,
                  user_id: user_id,
                }).then((results) => {
                  if (results.data) {
                    setLoadingSearch(false);
                    setOrders(results.data.orderInvoices);
                  }
                });
              }
            }}
            onClose={() => {
              setOrders([]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search orders"
                margin="none"
                InputProps={{
                  ...params.InputProps,
                  type: "search",

                  endAdornment: (
                    <InputAdornment position="end">
                      {loading_search ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {showTable ? (
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key="DivisionCategory"
                        align="left"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <b>Order Number</b>
                      </TableCell>
                      <TableCell
                        key="OrderNumber"
                        align="left"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <b>Status</b>
                      </TableCell>
                      <TableCell
                        key="Status"
                        align="left"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <b>Date Submitted</b>
                      </TableCell>
                      <TableCell
                        key="DateSubmited"
                        align="left"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <b>Total</b>
                      </TableCell>
                      <TableCell
                        key="Total"
                        align="left"
                        padding="none"
                        className={classes.tableCellName}
                      >
                        <b>Shipping info</b>
                      </TableCell>
                      <TableCell
                        key="ShippingInfo"
                        align="left"
                        padding="none"
                        className={classes.tableCellName}
                      >
                        <b>Items</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingtable
                      ? [1, 2, 3, 4, 5].map((index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Skeleton />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Skeleton />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Skeleton />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <Skeleton />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCellName}
                              >
                                <Skeleton />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCellName}
                              >
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : OrderInvoices.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.id}
                              onClick={() =>
                                role == "customer"
                                  ? history.push(`/store/orders/${row.id}`)
                                  : history.push(`/app/orders/${row.id}`)
                              }
                            >
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.id}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.Status}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.created_at}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {`$${Number(row.Total).toFixed(2)}`}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCellName}
                              >
                                {row.address}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCellName}
                              >
                                {row.ProductsName}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={OrderInvoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
              style={{ paddingLeft: "10px" }}
            >
              No orders to display
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

UserProfile.propTypes = {
  clients: PropTypes.array,
};
