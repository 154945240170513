import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AlertDialog from "../AlertDialog";
import { Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ApprovalGroupModal from "../client/ApprovalGroupModal";

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  action_buttons: {
    marginTop: 10,
  },
  datePickerIconbutton: {
    "& div": {
      "& div": {
        "& > button": {
          marginRight: "-15px",
        },
      },
    },
  },
}));

function UserDialog({
  open,
  handleClose,
  roles,
  formik,
  removeUser,
  divisionsPoints,
  division_categories,
  approval_groups,
  user_division_categories,
  addDivisionCategory,
  removeDivisionCategory,
  fromClient,
  saving,
  setCustomer,
  currentClient,
  getApprovalGroups = () => {},
}) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const role = localStorage.getItem("role");
  const [openApprovalGroupModal, setOpenApprovalGroupModal] = useState(false);

  useEffect(() => {
    formik.resetForm();
  }, [divisionsPoints]);

  /**
   * Close approval group modal
   */
  const closeApprovalGroups = () => {
    setOpenApprovalGroupModal(false);
  };

  return (
    <Dialog
      id="user_dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="user_dialog_title"
      maxWidth="sm"
      style={{ display: openApprovalGroupModal ? "none" : "" }}
    >
      <form
        onSubmit={formik.handleSubmit}
        id="user_dialog_form"
        disabled={role == "clientusermanager" && formik.values.role == 9}
      >
        <DialogTitle id="user_dialog_title">
          {formik.values.id == null ? "Create User" : "Edit User"}
        </DialogTitle>
        <DialogContent id="user_dialog_content" style={{ overflowY: "hidden" }}>
          <Grid container spacing={2} id="user_dialog_container">
            {/* First name */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                autoFocus
                id="first_name"
                margin="dense"
                label="First Name"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                fullWidth
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            {/* Last name */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="last_name"
                margin="dense"
                label="Last Name"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                fullWidth
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            {/* Username */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="username"
                margin="dense"
                label="Username"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.username}
                fullWidth
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            {/* Password */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="password"
                type="password"
                margin="dense"
                label="Password"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.password}
                fullWidth
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="email"
                type="email"
                margin="dense"
                label="Email"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            {/* Billing department */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="billing_department"
                margin="dense"
                label="Billing Department"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.billing_department}
                fullWidth
              />
            </Grid>
            {/* Site */}
            <Grid item xs={8} md={6}>
              <TextField
                size="small"
                id="site"
                margin="dense"
                label="Site"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.site}
                fullWidth
              />
            </Grid>
            {/* Active */}
            <Grid item xs={4} md={6} style={{ marginTop: "8px" }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.confirmed}
                      onChange={formik.handleChange}
                      name="confirmed"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </FormGroup>
            </Grid>
            {/* Role */}
            <Grid item xs={12} md={6}>
              <FormControl
                id="role_form_control"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                <InputLabel id="role_label">Role</InputLabel>
                <Select
                  id="role_select"
                  labelId="role_label"
                  label="Role"
                  name="role"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  disabled={
                    role == "clientusermanager" && formik.values.role == 9
                  }
                  value={formik.values.role}
                  onChange={(event, child) => {
                    formik.handleChange(event, child);
                  }}
                >
                  {setCustomer(formik.values.role == 7 ? true : false)}
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.role && formik.errors.role}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Approval group */}
            {!fromClient ||
            (currentClient &&
              currentClient.mode &&
              currentClient.mode === "Approval") ? (
              <Grid item xs={12} md={6}>
                <FormControl
                  id="approval_group_form_control"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  error={
                    formik.touched.group_ids && Boolean(formik.errors.group_ids)
                  }
                >
                  <InputLabel id="approval_group_label">
                    Approval Group
                  </InputLabel>
                  <Select
                    id="approval_group_select"
                    labelId="approval_group_label"
                    label="Approval Group"
                    name="group_ids"
                    disabled={
                      approval_groups.length == 0 && formik.values.role != 7
                    }
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    }}
                    value={formik.values.group_ids}
                    onChange={(event, child) => {
                      const value = event?.target?.value || "";
                      if (value === "AddNewApprovalGroup") {
                        setOpenApprovalGroupModal(true); // Open approval group modal
                      } else {
                        formik.handleChange(event, child);
                      }
                    }}
                  >
                    {approval_groups.length > 0 && formik.values.role != 7 ? (
                      <MenuItem value={0}>None</MenuItem>
                    ) : null}
                    {approval_groups.length > 0 ? (
                      approval_groups.map((approval_group) => (
                        <MenuItem
                          key={approval_group.id}
                          value={approval_group.id}
                        >
                          {approval_group.Name}
                        </MenuItem>
                      ))
                    ) : approval_groups.length === 0 &&
                      formik.values.role == 7 ? (
                      <MenuItem value={"AddNewApprovalGroup"}>
                        Add New Approval Group
                      </MenuItem>
                    ) : (
                      <MenuItem value={0} disabled>
                        No approval groups
                      </MenuItem>
                    )}
                  </Select>
                  <FormHelperText>
                    {formik.touched.group_ids && formik.errors.group_ids}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
            {/** Hire Date */}
            {fromClient && (
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.datePickerIconbutton}
                    fullWidth
                    inputVariant="outlined"
                    size="small"
                    margin="dense"
                    maxDate={new Date()}
                    placeholder="mm/dd/yyyy"
                    id="date-picker-dialog"
                    label="Hire Date"
                    format="MM/dd/yyyy"
                    value={formik.values.hireDate}
                    onChange={(date) => {
                      formik.handleChange({
                        target: { name: "hireDate", value: date },
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {/* Employee_ID */}
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="Employee_ID"
                margin="dense"
                label="Employee ID"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.Employee_ID}
                fullWidth
                error={
                  formik.touched.Employee_ID &&
                  Boolean(formik.errors.Employee_ID)
                }
                helperText={
                  formik.touched.Employee_ID && formik.errors.Employee_ID
                }
              />
            </Grid>
            {fromClient ? (
              <Grid item xs={12}>
                <Typography variant={"h6"}>User Points Management</Typography>{" "}
              </Grid>
            ) : (
              ""
            )}
            {/* Search division categories */}
            {fromClient ? (
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  style={{ marginLeft: "auto" }}
                  id="search"
                  disableClearable
                  selectOnFocus
                  inputValue={searchValue}
                  value={selectedOptionValue}
                  options={division_categories}
                  getOptionLabel={(option) => option.Name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search division categories"
                      margin="none"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    addDivisionCategory(value);
                    setSelectedOptionValue("");
                    setSearchValue("");
                  }}
                />
              </Grid>
            ) : (
              ""
            )}
            {/* Division categories */}
            {fromClient
              ? formik.values.division_categories.map((category, index) => {
                  return (
                    <Grid
                      key={category.id}
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: user_division_categories.includes(category.id)
                          ? "block"
                          : "none",
                      }}
                    >
                      <TextField
                        name={`division_categories[${index}].balance`}
                        style={{ width: "80%" }}
                        label={
                          category.name.length > 20
                            ? category.name.substring(0, 20) + "..."
                            : category.name
                        }
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        variant="outlined"
                        size="small"
                        value={category.balance}
                        onChange={formik.handleChange}
                      />

                      <IconButton
                        aria-label="delete"
                        style={{ width: "20%" }}
                        onClick={() => removeDivisionCategory(category)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  );
                })
              : ""}
          </Grid>
          {/* Buttons */}
          <Grid
            container
            spacing={2}
            id="buttons_container"
            className={classes.action_buttons}
          >
            <Grid item xs={2}>
              {formik.values.id == null ? (
                ""
              ) : (
                <AlertDialog
                  dialogTitle="Delete User"
                  dialogContentText="User will be removed from the list. Do you want to continue?"
                  confirmButtonClick={() => removeUser(formik.values.id)}
                />
              )}
            </Grid>
            <Grid item xs={4} md={6}></Grid>
            <Grid item xs={3} md={2}>
              <Button onClick={handleClose} variant="contained" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={
                  saving ||
                  (role == "clientusermanager" && formik.values.role == 9)
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
      {/**Crete approval group modal */}
      {openApprovalGroupModal && (
        <ApprovalGroupModal
          open={openApprovalGroupModal}
          handleClose={closeApprovalGroups}
          reloadClient={() => getApprovalGroups(true)}
          selectedApprovalGroup={{}}
        />
      )}
    </Dialog>
  );
}

UserDialog.propTypes = {
  open: PropTypes.bool,
  fromClient: PropTypes.bool,
  handleClose: PropTypes.func,
  division_categories: PropTypes.array,
  approval_groups: PropTypes.array,
  user_division_categories: PropTypes.array,
  divisionsPoints: PropTypes.array,
  roles: PropTypes.array,
  formik: PropTypes.object,
  removeUser: PropTypes.func,
  addDivisionCategory: PropTypes.func,
  removeDivisionCategory: PropTypes.func,
  saving: PropTypes.bool,
  setCustomer: PropTypes.func,
  currentClient: PropTypes.object,
  getApprovalGroups: PropTypes.func,
};

export default UserDialog;
