// Import Libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";

// Custom Components
import SnackbarMessage from "../SnackbarMessage";
import SnackbarNotification from "../SnackbarNotification";

// Import API Services
import {
  getClientMessages,
  updateClientMessage,
  CreateClientMessage,
} from "../../api/Clients";

/**
 * Custom Styles
 */
const useStyles = makeStyles(() => ({
  paper: {
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  datePicker: {
    "& div": {
      height: "37px",
    },
    "& > label": {
      marginTop: "-7px",
    },
  },
  TextField: {
    "& div": {
      height: "65px",
    },
  },
  saveButton: {
    marginTop: "15px",
    float: "right",
  },
  datePickerError: {
    "& fieldset": {
      border: "1px solid #f44336",
    },
    "& div": {
      height: "37px",
    },
    "& > label": {
      marginTop: "-7px",
      color: "#f44336",
    },
    "& > p": {
      color: "#f44336",
    },
  },
}));

export default function InformationMessage(props) {
  const classes = useStyles();
  const { loading, clientId } = props;
  const [loadingMessages, setLoadingMessages] = useState(true);
  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });
  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    message: "",
    data: {},
  });
  // Form Initial values
  const initialValues = {
    id: "",
    message: "",
    startDate: null,
    endDate: null,
    published_at: null,
    clientId: clientId,
  };

  /**
   * Formik validation schema
   */
  const validationSchema = yup.object({
    message: yup
      .string("message")
      .min(0, "")
      .max(255, "The message must have a maximum length of 255 characters")
      .required("Message is required"),
    startDate: yup
      .date("startDate")
      .required("Start date is required")
      .nullable()
      .min(
        moment().format("MM/DD/YYYY"),
        `Cannot save dates prior to the current one`
      ),
    endDate: yup
      .date("endDate")
      .required("End date is required")
      .nullable()
      .min(
        moment().format("MM/DD/YYYY"),
        `Cannot save dates prior to the current one`
      ),
  });

  /**
   * Use formik
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => createUpdateMessage(values),
  });

  // Functions

  // Inital values
  useEffect(() => {
    // Get Client Message
    GetClientMessages();
  }, [clientId]);

  /**
   * Get Client Messages
   */
  const GetClientMessages = () => {
    setLoadingMessages(true);
    getClientMessages(clientId).then((result) => {
      if (result && result.data && result.data.informationMessages) {
        const message = result.data.informationMessages;
        if (message && Array.isArray(message) && message.length !== 0) {
          initializeValues(message[0]);
        } else {
          const message = {
            id: "",
            message: "",
            startDate: null,
            endDate: null,
            published_at: null,
          };
          initializeValues(message);
        }
      } else {
        setError({
          open: true,
          message: `An error occurred, please try again`,
          severity: "error",
        });
      }
    });
  };

  /**
   * Set Initial values
   * @param {Object} message
   */
  const initializeValues = (message) => {
    formik.setFieldValue("id", message.id, false);
    formik.setFieldValue("message", message.message, false);
    formik.setFieldValue(
      "startDate",
      message && message.startDate
        ? moment(message.startDate).format("MM/DD/YYYY")
        : null,
      false
    );
    formik.setFieldValue(
      "endDate",
      message && message.endDate
        ? moment(message.endDate).format("MM/DD/YYYY")
        : null,
      false
    );
    formik.setFieldValue(
      "published_at",
      message && message.published_at ? message.published_at : null,
      false
    );
    setLoadingMessages(false);
  };

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**
   * Create or Update Client Message
   * @param {Object} message Message required params
   * @param {Boolean} update Update registry to deactivate it
   */
  const createUpdateMessage = (message, update = false) => {
    // Set clientId
    message.clientId = clientId;
    // Get action - Update | Create
    const action =
      message && message.id && message.id !== ""
        ? updateClientMessage
        : CreateClientMessage;
    // Validate required params
    message.startDate =
      message && message.startDate
        ? moment(message.startDate).format("YYYY-MM-DD")
        : "";
    message.endDate =
      message && message.endDate
        ? moment(message.endDate).format("YYYY-MM-DD")
        : "";
    // Set published_at value to null
    if (update) {
      message.published_at = null;
    }
    // Making Request
    action(message).then((result) => {
      if (
        message.id !== "" &&
        result &&
        result.data &&
        result.data.updateInformationMessage &&
        result.data.updateInformationMessage.informationMessage
      ) {
        GetClientMessages();
        if (update) {
          message.published_at = moment().format();
          setSnackbarAlert({
            open: true,
            message: `Message Cleared`,
            data: message,
          });
        } else {
          setError({
            open: true,
            message: `Message Updated Successfully`,
            severity: "success",
          });
        }
      } else if (
        result &&
        result.data &&
        result.data.createInformationMessage &&
        result.data.createInformationMessage.informationMessage
      ) {
        GetClientMessages();
        setError({
          open: true,
          message: `Message Created Successfully`,
          severity: "success",
        });
      } else {
        setError({
          open: true,
          message: `An error occurred, please try again`,
          severity: "error",
        });
      }
    });
  };

  // Close snackbarAlert
  const handleCloseSnackbarAlert = () => {
    setSnackbarAlert({
      open: false,
      message: "",
      data: {},
    });
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {loading || loadingMessages ? (
          //Skeleton loading
          <Grid container spacing={2}>
            {/** Title */}
            <Grid id="grid_title" xs={12} item sm={6} md={6}>
              <Skeleton height={41.6} />
            </Grid>
            {/**Label */}
            <Grid id="grid_title" xs={12} item sm={12} md={12}>
              <Skeleton height={41.6} />
            </Grid>
            {/**Message */}
            <Grid id="grid_title" xs={12} item sm={12} md={12}>
              <Skeleton height={41.6} />
            </Grid>
            {/** Start date */}
            <Grid id="grid_title" xs={12} item sm={6} md={6}>
              <Skeleton height={41.6} />
            </Grid>
            {/** End date */}
            <Grid id="grid_title" xs={12} item sm={6} md={6}>
              <Skeleton height={41.6} />
            </Grid>
          </Grid>
        ) : (
          //Info message container
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {/* Title */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h4">Info Message</Typography>
              </Grid>
              {/**Message*/}
              <Grid item xs={12} sm={12}>
                <Typography>This message will be shown in the store</Typography>
                <TextField
                  className={classes.TextField}
                  multiline
                  maxRows={4}
                  fullWidth
                  size="small"
                  id="message"
                  name="message"
                  margin="dense"
                  label="Message"
                  variant="outlined"
                  value={formik.values.message}
                  onChange={(event) => {
                    const message = event.target.value;
                    if (message.length > 255) {
                      event.target.value = message.substring(0, 255);
                    }
                    formik.handleChange(event);
                  }}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              {/**Start date */}
              <Grid item xs={12} sm={12} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    onChange={(value) => {
                      const date = moment(value);
                      date.isValid();
                      formik.setFieldValue(
                        "startDate",
                        date._isValid ? date._d : null,
                        date._isValid
                      );
                    }}
                    name="startDate"
                    value={formik.values.startDate}
                    maxDate={
                      formik.values.endDate ? formik.values.endDate : null
                    }
                    minDate={new Date()}
                    inputFormat="MM/dd/yyyy"
                    label="Start Date"
                    renderInput={(params) => (
                      <TextField
                        className={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                            ? classes.datePickerError
                            : classes.datePicker
                        }
                        error={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                        }
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {/**End date */}
              <Grid item xs={12} sm={12} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    onChange={(value) => {
                      const date = moment(value);
                      date.isValid();
                      formik.setFieldValue(
                        "endDate",
                        date._isValid ? value : null,
                        date._isValid
                      );
                    }}
                    name="endDate"
                    value={formik.values.endDate}
                    minDate={new Date()}
                    inputFormat="MM/dd/yyyy"
                    label="End Date"
                    renderInput={(params) => (
                      <TextField
                        className={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                            ? classes.datePickerError
                            : classes.datePicker
                        }
                        error={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                        }
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {/**Clear Button */}
              <Grid item sm={3} lg={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  style={{ marginTop: "15px" }}
                  onClick={() => {
                    // Get Message id
                    const id = formik.values.id;
                    if (id && id !== "") {
                      createUpdateMessage(formik.values, true);
                    } else {
                      formik.resetForm();
                    }
                  }}
                  disabled={formik.values.message.length == 0}
                >
                  CLEAR
                </Button>
              </Grid>
              <Grid item xs={0} sm={6} lg={6}></Grid>
              {/**Clear Button */}
              <Grid item xs={12} sm={3} lg={3}>
                <Button
                  className={classes.saveButton}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    formik.values.message.length == 0 &&
                    !formik.values.startDate &&
                    !formik.values.endDate
                  }
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
      {/**Clean Message form */}
      <SnackbarNotification
        message={snackbarAlert.message}
        open={snackbarAlert.open}
        handleClose={handleCloseSnackbarAlert}
        actionFunction={() => {
          createUpdateMessage(snackbarAlert.data);
          handleCloseSnackbarAlert();
        }}
      />
    </div>
  );
}

InformationMessage.propTypes = {
  loading: PropTypes.bool,
  clientId: PropTypes.number,
};
