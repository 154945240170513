// Libraries
import React, { useEffect, useState } from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Custom components
import AcumaticaSettings from "../components/client/AcumaticaSettings";
import NavigationTabs from "../components/NavigationTabs";
import ClientContainer from "../components/client/ClientContainer";
import ClientContact from "../components/client/ClientContact";
import ApprovalGroups from "../components/client/ApprovalGroups";
import DivisionCategories from "../components/client/DivisionCategories";
import ClientSheduler from "../components/client/ClientScheduler";
import SnackbarMessage from "../components/SnackbarMessage";
import UserAddresses from "../components/User/UserAddresses";
import InformationMessage from "../components/client/InformationMessage";

// API
import { getClientOverviewInfo } from "../api/Clients";
import { updateClientCustomAddresses } from "../api/Clients";

//Styles needed for responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  toolbar: theme.mixins.toolbar,
}));

/**
 * Main component
 * @param {object} props Component props
 * @returns
 */
const ClientOverview = (props) => {
  let { client_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [division_categories, setDivisionCategories] = useState([]);
  const initial_acumatica_settings = {
    Company: "",
    CustomerId: "",
    EndpointVersion: "",
    Location: "",
    Name: "",
    Password: "",
  };
  let [client, setClient] = useState({
    name: "",
    subdomain: "",
    id: "",
    contact_user: [],
    approval_groups: [],
    client_image: null,
    primary_color: "",
    secondary_color: "",
    ordering_mode: "Default",
    enabled_acumatica: false,
    acumatica_settings: initial_acumatica_settings,
    use_billing_department: false,
    custom_addresses: true,
    acumatica_setting: "Resellersettings",
  });
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getClientOverviewInfo(client_id).then((result) => {
      if (result) {
        if (isMounted) {
          setClient({
            name: result.name,
            subdomain: result.subdomain,
            id: client_id,
            contact_user: result.contact_user_ids
              ? result.contact_user_ids
              : false,
            approval_groups: result.approval_groups.map((group) => {
              return {
                ...group,
                approvals: group.users
                  ? group.users.filter((user) =>
                      ["3", "4", "5", "9"].includes(user.role.id)
                    )
                  : [],
                users: group.users
                  ? group.users.filter((user) => ["7"].includes(user.role.id))
                  : [],
              };
            }), // map filter users by role
            client_image: result.client_image,
            primary_color: result.primary_color,
            secondary_color: result.secondary_color,
            ordering_mode: result.mode ? result.mode : "Default",
            enabled_acumatica: result.acumatica,
            use_billing_department: Boolean(result.use_billing_department),
            acumatica_settings: result.AcumaticaSetting
              ? result.AcumaticaSetting
              : initial_acumatica_settings,
            custom_addresses: result.custom_addresses,
            acumatica_setting: result.AcumaticaResellerClientSettings,
          });
          setLoading(false);
        }
        return () => {
          isMounted = false;
        };
      }
    });
    //Set client_id to localStorage
    const role = localStorage.getItem("role");
    if (
      (client_id && role && role == "superadmin") ||
      role == "reselleradmin"
    ) {
      localStorage.setItem("client_id", client_id);
    }
  }, [client_id]);

  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  const reloadClient = () => {
    setLoading(true);
    getClientOverviewInfo(client_id).then((result) => {
      setClient({
        name: result.name,
        subdomain: result.subdomain,
        id: client_id,
        contact_user: result.contact_user_ids ? result.contact_user_ids : false,
        approval_groups: result.approval_groups.map((group) => {
          return {
            ...group,
            approvals: group.users
              ? group.users.filter((user) =>
                  ["3", "4", "5", "9"].includes(user.role.id)
                )
              : [],
            users: group.users
              ? group.users.filter((user) => ["7"].includes(user.role.id))
              : [],
          };
        }), // map filter users by role
        client_image: result.client_image,
        primary_color: result.primary_color,
        secondary_color: result.secondary_color,
        ordering_mode: result.mode ? result.mode : "Default",
        enabled_acumatica: result.acumatica,
        use_billing_department: Boolean(result.use_billing_department),
        acumatica_settings: result.AcumaticaSetting
          ? result.AcumaticaSetting
          : initial_acumatica_settings,
        custom_addresses: result.custom_addresses,
        acumatica_setting: result.AcumaticaResellerClientSettings,
      });
      setLoading(false);
    });
  };

  const changeCustomAddresses = (event) => {
    let values = {
      id: client_id,
      custom_addresses: event.target.checked,
    };
    updateClientCustomAddresses(values).then((result) => {
      if (result.data.updateClient) {
        reloadClient();
      }
    });
  };
  return (
    <Container className={classes.mainContainer} maxWidth="xl">
      <Grid container style={{ marginTop: "65px" }}>
        {client_id == 0 ? (
          <Grid
            container
            style={{ padding: "0 20px 20px 20px", marginTop: "20px" }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                This user doesn&apos;t have any client, please contact an
                administrator.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs={12} md={10} style={{ paddingBottom: "20px" }}>
              <NavigationTabs
                client_id={Number(client_id)}
                value={"overview"}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Grid item xs={12}>
                  <ClientContainer
                    client={client}
                    setMessage={setError}
                    getClients={props.getClients}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <ClientContact
                    contact_user={client.contact_user}
                    reloadClient={reloadClient}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <ClientSheduler
                    client_id={client_id}
                    division_categories={division_categories}
                    setError={setError}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <InformationMessage loading={loading} clientId={client_id} />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid item xs={12}>
                  <UserAddresses
                    global={true}
                    client_id={client_id}
                    changeCustomAddresses={changeCustomAddresses}
                    custom_addresses={client.custom_addresses}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <ApprovalGroups
                    approvalGroups={client.approval_groups}
                    reloadClient={reloadClient}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <DivisionCategories
                    client_id={client_id}
                    division_categories={division_categories}
                    setDivisionCategories={setDivisionCategories}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <AcumaticaSettings
                    client_id={client_id}
                    loading={loading}
                    enabled_acumatica={client.enabled_acumatica}
                    acumatica_settings={client.acumatica_settings}
                    reloadClient={reloadClient}
                    use_billing_department={client.use_billing_department}
                    acumatica_setting={client.acumatica_setting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
};

ClientOverview.propTypes = {
  getClients: PropTypes.func,
};

export default ClientOverview;
